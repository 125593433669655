/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"

const Layout = ({ children }) => {
  const theme = createMuiTheme({
    props: {
      MuiButton: {
        disableElevation: true,
        style: {
          borderRadius: "0",
          textTransform: "unset",
          fontWeight:"600",
        },
      },
    },
  })

  return (
    <>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
          position: `relative`,
        }}
      >
        <main>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
